<template>
    <v-container>
        <v-row no-gutters>
            <v-col>
                <div>
                    <v-skeleton-loader type="table" v-if="api.isLoading" ref="skeleton">

                    </v-skeleton-loader>
                    <v-data-table
                    :items="this.filteredCompanies"
                    :headers="this.companiesNewHeader"
                    :search="searchCompanies"
                    :sort-by="['companies_updated_at']"
                    :sort-desc="[true]"
                    class="elevation-1"
                    dense 
                    v-if="this.companiesNew!=null"
                    >
                    
                    <template v-slot:top>
                        <v-toolbar flat height="auto">
                            <v-row no-gutters>
                                <v-col class="ma-4">
                                    <v-row >
                                        <v-toolbar-title class="font-weight-bold text-h4 my-3">
                                            First Subscription
                                        </v-toolbar-title>

                                    </v-row>
                                 
                                    <v-row class="mt-5 ">
                                        <v-col>
                                            <v-text-field class="" dense outlined label="Search..."
                                            v-model="searchCompanies">  
    
                                            </v-text-field>

                                        </v-col>
                                        <v-col>
                                            <div class="d-flex flex-row">
                                                <div class="">
                                                    <v-text-field style="border-radius:4px 0px 0px 4px" dense outlined 
                                                    prepend-inner-icon="mdi-calendar"
                                                    @click:prepend-inner="()=>{
                                                        isStartDate = true;
                                                    }"
                                                    @click="()=>{
                                                        isStartDate =true;
                                                    }"
                                                    label="Start Date..."
                                                    v-model = "startDate">
                        
                                                    </v-text-field>
                        
                                                    <v-dialog v-model="isStartDate" height="auto" width="auto">
                                                        <v-card class="pa-3">
                                                            <div class="d-flex flex-column">
                                                                <p>
                                                                    Start Date
                                                                </p>
                                                                <div>

                                                                    <v-date-picker flat v-model="startDate" :landscape="true"
                                                                    :reactive="true">
                                                                    </v-date-picker>

                                                                    <slot name="footer">
                                                                        <div class="d-flex justify-end white" >
                                                                            <v-btn color="primary" plain @click="()=>{
                                                                                isStartDate=false;
                                                                                startDate=null;
                                                                            }">
                                                                                Cancel

                                                                            </v-btn>
                                                                            <v-btn color="primary" @click="()=>{
                                                                                isStartDate = false;
                                                                            }">
                                                                                Confirm
                                                                            </v-btn>
                                                                        </div>
                                                                    </slot>
                                                                </div>
                                                            </div>
                                                        </v-card>
                                                        
                                                    
                                                    </v-dialog>

                                                </div>
                                                <div>
                                                    <v-text-field dense outlined style="border-radius:0px"
                                                    prepend-inner-icon="mdi-calendar"
                                                    @click:prepend-inner="()=>{
                                                        isEndDate = true;
                                                    }"
                                                    @click="()=>{
                                                    isEndDate =true;
                                                    }"
                                                    label="End Date..."
                                                    v-model = "endDate">
                        
                                                    </v-text-field>
                                                   
                                                    <v-dialog v-model="isEndDate" height="auto" width="auto">
                                                        <v-card class="pa-3">
                                                        <div class="d-flex flex-column">
                                                            <p>
                                                                End Date
                                                            </p>
                                                            <div>
                                                                <v-date-picker flat v-model="endDate" :landscape="true"
                                                                :reactive="true">
                                                                </v-date-picker>
                                                            </div>
                                                        </div>
                                                        <div name="footer">
                                                            <div class="d-flex justify-end my-3">
                                                                <v-btn color="primary" plain @click="()=>{
                                                                    isEndDate = false;
                                                                    endDate=null;
                                                                }">
                                                                    Cancel
                                                                </v-btn>
                                                                <v-btn color="primary" @click="()=>{
                                                                    isEndDate = false;
                                                                }">
                                                                    Confirm
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </v-card>
                                                       
                                                    </v-dialog>
                                                </div>
                                                <div>

                                                    <v-btn color="primary" :loading="isLoadingFilter" @click="filterDate" style="border-radius:0px 4px 4px 0px;height:40px">
                                                        Filter
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </v-col>

                                    </v-row>
                                    <v-row class="d-flex justify-end my-4">
                                `       <v-btn color="primary" outlined class="mx-2" @click="resetFilter">
                                             Reset Filter
                                        </v-btn>
                                        <JsonCSV :data="downloadCompanies">
                                            <v-btn color="primary">
                                                Download
                                            </v-btn>
                                        </JsonCSV>

                                `    </v-row>
                                </v-col>
                            </v-row>
                                  
                                        
                        </v-toolbar>
                    </template>

                    <template v-slot:item.company_name="{item}">
                        <router-link :to="{name:'PageCompaniesDetail',params:{id:item.company_id}}">
                            {{ item.company_name }}
                        </router-link>
                    </template>

                    <!-- <template v-slot:item.Index="{item}"> -->
                    <!-- <template v-slot:item.Index="{item}">
                        {{ filteredCompanies.indexOf(item)+1 }}
                    </template> -->

                    <template v-slot:item.joiner_date="{item}">
                        <span v-if="item.joiner_date!=null">
                            {{ convertTimeZone(item.joiner_date) }}
                        </span>
                    </template>
                    </v-data-table>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default{
    data:()=>({
        renewalStatusOption:[
            'Pending',
            'Unreachable',
            'Confirm',
            'Rejected',
        ],
        searchCompanies:null,
        downloadCompanies:null,
        isStartDate:false,
        isEndDate:false,
        startDate:null,
        endDate:null,
        companiesNew : null,
        filteredCompanies:null,
        companiesNewHeader :[
            // {
            //     text:'#',
            //     value:'Index',
            //     align:'start',
            //     sortable:false,
            // },
            {
                text:'Name',
                value:'company_name',
            },
            {
                text:'Subscription Date',
                value:'joiner_date',
            },
            
            // {
            //     text:'New Joiner',
            //     value:'companies_new_joiner'
            // }
            
        ],
        isLoadingFilter:false,
        api : {
                isLoading:false,
                isError:false,
                error:null,
                url:null,
            },
        
    }),
    created(){
        this.api.callbackReset = () =>
        {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {
            if(resp.class=="getCompaniesClientActiveSubscription"){
                this.companiesNew =  resp.data;
                this.filteredCompanies =resp.data;
                this.downloadCompanies = this.filteredCompanies.map(obj=>({
                    "Company":obj.company_name,
                    "Join Date":this.convertTimeZone(obj.joiner_date),
                }))
                // this.filterNewCompanies();
            }
            this.api.isLoading = false;

        }
    },
    mounted(){
        this.fetch();
    },
    methods:{
        fetch(){
            let fetchNewCompaniesApi = this.fetchNewCompanies();
            this.$api.fetch(fetchNewCompaniesApi);
        },
        fetchNewCompanies(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/company/active/new/subscription";
            return tempApi;
        },
        filterNewCompanies(){
            let tempCompanies = this.$_.clone(this.companiesNew)
            let tempFilteredCompanies = tempCompanies.filter(function(item){
                if(item.companies_new_joiner==true){
                    return true;
                }
            })

           

            this.filteredCompanies = tempFilteredCompanies;
        },
        filterDate(){
            this.isLoadingFilter = true;
            let startDate = this.$_.clone(this.startDate);
            let endDate = this.$_.clone(this.endDate);
            let tempCompanies = this.$_.clone(this.companiesNew);
            // if(startDate!=null && endDate!=null){
            //     tempCompanies = tempCompanies.filter(function(item){
            //         if(item.joiner_date>startDate && item.joiner_date<endDate){
            //             return true;
            //         }
            //     });
            // }

            if (startDate!=null){
                tempCompanies = tempCompanies.filter(function(item){
                    let tempJoinerDate = (item.joiner_date!=null)?item.joiner_date.split(" ")[0]:null;
                    if(tempJoinerDate>=startDate){
                        return true;
                    }
                })
            }

            if(endDate!=null){
                tempCompanies = tempCompanies.filter(function(item){
                  
                  let tempJoinerDate = (item.joiner_date!=null)?item.joiner_date.split(" ")[0]:null;
                  if(tempJoinerDate<=endDate){
                      return true;
                  }
              })
            }

            this.filteredCompanies = tempCompanies;

            this.downloadCompanies = this.filteredCompanies.map(obj=>({
                    "Company":obj.company_name,
                    "Join Date":this.convertTimeZone(obj.joiner_date),
                }))

            this.isLoadingFilter = false;

        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL');
        },
        resetFilter(){
            this.filteredCompanies = this.$_.clone(this.companiesNew);
            this.startDate = null;
            this.endDate = null;
            this.searchCompanies = null;
            this.downloadCompanies = this.filteredCompanies.map(obj=>({
                    "Company":obj.company_name,
                    "Join Date":this.convertTimeZone(obj.joiner_date),
                }))
        }
       
    }
}
</script>